<template>
  <section :class="$style.container" class="flex flex-column">
    <loader v-if="isLoading" center :animated="false" data-testid="loader" />
    <room-listeners
      v-else-if="listeners.length"
      :listeners="listeners"
      :class="$style.list"
      :available-view-profile="availableRoomModules.listeners_profile"
    />
    <div v-else :class="$style.notEnough" class="flex flex-center">
      {{ $t("listeners.notEnough") }}
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { listeners, room } from "@/store/modules/store.namespaces";
import { GET_LISTENERS } from "@/store/modules/listeners/action-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import RoomListeners from "@/components/room/listeners/RoomListeners";
import Loader from "@/components/common/elements/Loader";

/**
 * Компонент страницы списка пользователей
 * @vue-data {Boolean} isLoading - ожидаем загрузки профиля
 * @vue-computed {Array} listeners - список пользователей
 * @vue-computed {Object} availableRoomModules - доступные модули комнаты
 */
export default {
  name: "ViewListeners",
  components: {
    RoomListeners,
    Loader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(listeners, ["listeners"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
  },
  async created() {
    this.isLoading = await this.getListeners();
  },
  methods: {
    ...mapActions(listeners, {
      getListeners: GET_LISTENERS,
    }),
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  max-width: 980px;
  padding: 15px 10px;
  margin: 0 auto;
}

.list {
  flex: 1 0 auto;
}

.notEnough {
  height: 100%;
  font-size: 20px;
}
</style>
