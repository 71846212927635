<template>
  <div
    :class="{
      [`${$style.fullInfo} flex flex-column`]: fullInfo,
      [$style.profile]: profile,
    }"
  >
    <div :class="$style.avatar" class="flex align-center" data-testid="avatar">
      <user-avatar :src="user.avatar" :alt="user.name.join(' ')" :class="$style.avatar__img" />
      <user-upload-avatar
        v-if="profile"
        :class="[$style.avatar__upload, { [$style.avatar__upload_inProcess]: avatarUpload }]"
        :in-process="avatarUpload"
        @upload="$emit('upload', $event)"
      />
    </div>
    <div :class="$style.info" class="flex flex-column">
      <div :class="$style.info__main">
        <div :class="[$style.info__fio, { 'semi-bold': fullInfo }]" class="flex flex-column">
          <span
            v-for="(item, idx) in user.name"
            :key="idx"
            v-line-clamp="3"
            :class="$style.info__fioItem"
          >
            {{ item }}
          </span>
        </div>
        <div class="flex flex-column" :class="[$style.info__position, { 'text-md': fullInfo }]">
          <span
            v-for="(item, idx) in user.position"
            :key="idx"
            v-line-clamp="3"
            :class="$style.info__position"
          >
            {{ item }}
          </span>
        </div>
        <div v-if="fullInfo" data-testid="additionalInfo" class="flex flex-column">
          <div
            v-if="user.description"
            :class="[$style.info__description, $style.pushedBorder]"
            v-html="user.description"
          ></div>
          <div v-if="user.email || user.phone" :class="[$style.info__data, $style.pushedBorder]">
            <div v-if="user.email" :class="$style.option" :title="user.email">
              <span :class="$style.option__name">{{ $t("Login.email") }}</span>
              <div :class="$style.option__value">
                <a :class="$style.link" :href="`mailto:${user.email}`">{{ user.email }}</a>
              </div>
            </div>
            <div v-if="user.phone" :class="$style.option" :title="user.phone">
              <span :class="$style.option__name">{{ $t("listeners.phone") }}</span>
              <div :class="$style.option__value">
                <a :class="$style.link" :href="`tel:${user.phone}`">{{ user.phone }}</a>
              </div>
            </div>
          </div>
          <div v-if="user.location" :class="$style.pushedBorder">
            <span :class="$style.info__location">{{ user.location }}</span>
          </div>
        </div>
      </div>
      <div v-if="profile" data-testid="authInfo" :class="$style.authInfo">
        <div :class="$style.option" :title="user.login">
          <span :class="$style.option__name">{{ $t("Login.yourLogin") }}</span>
          <div :class="$style.option__value">{{ user.login }}</div>
        </div>
        <!--        <div>-->
        <!--          <ws-button color="primary" outlined font-size="lg" lg @click="$emit('change-pass')">-->
        <!--            {{ $t("RestorePassword.changePassword") }}-->
        <!--          </ws-button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/common/user/UserAvatar";
import UserUploadAvatar from "@/components/common/user/UserUploadAvatar";
// import WsButton from "@/components/base/WsButton";

/**
 * Карточка участника для отображения в списке
 * [Примеры отображения]{@link https://storybook.dev.whenspeak.ru/?path=/docs/user-card--without-avatar}
 *
 * @vue-prop {object} user - пользователь
 * @vue-prop {boolean} full-info - карточка меняет отображение на вывод полной информации
 * @vue-event {Blob} upload - загрузка аватара
 * @vue-prop {boolean} profile - карточка меняет отображение на вывод инфы о себе
 * @vue-prop {boolean} avatarUpload - идет процесс загрузки аватара
 */
export default {
  name: "UserCard",
  components: {
    UserAvatar,
    UserUploadAvatar,
    // WsButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    fullInfo: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Boolean,
      default: false,
    },
    avatarUpload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.avatar {
  position: relative;
  flex: 1 0 auto;
  margin-bottom: 5px;
  overflow: hidden;
  border: 1px solid var(--base-color);
  border-radius: 4px;

  &__img {
    font-size: 70px;
  }
}

.info {
  flex: 1 1 auto;
  width: 100%;
  padding: 5px;

  &__fio {
    margin-bottom: 5px;
    color: var(--base-color);
  }

  &__fioItem,
  &__position,
  &__location,
  &__description {
    word-break: break-word;
  }

  &__fioItem:first-of-type {
    font-size: 18px;
  }

  &__position {
    font-size: 14px;
    color: var(--mute-text-color);
  }

  &__contact {
    width: 50%;
  }
}

.fullInfo {
  padding: 25px 20px 20px;

  @include mq-tablet {
    flex-direction: row;
    padding: 50px;
  }

  .avatar {
    align-self: flex-start;
    min-width: 320px;
    max-width: 375px;
    margin: 0 auto;
    overflow: hidden;
    border: initial;
    border-radius: initial;

    @include mq-tablet {
      margin: 0 40px 0 0;
      border-radius: 5px;
    }

    &__img {
      font-size: 12em;
    }
  }

  .info {
    width: 100%;

    @include mq-tablet {
      flex-grow: 1;
      width: auto;
      padding: 0;
    }

    &__main {
      flex: 1 1 auto;
    }

    &__fio {
      font-size: 21px;
    }

    &__fioItem:first-of-type {
      font-size: 25px;
    }

    &__position {
      color: $gray-darken;
    }

    &__data {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 25px;
    }

    & > * {
      flex-shrink: 1;
    }
  }

  .link,
  .info__description > a {
    color: var(--ws-color-primary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.profile {
  .avatar {
    cursor: pointer;
    min-width: unset;
    max-width: 300px;
    width: 100%;

    &__upload {
      opacity: 0;
      transition: opacity 0.3s linear;

      &_inProcess {
        opacity: 1;
      }
    }

    &:hover .avatar__upload {
      opacity: 1;
    }
  }

  .info {
    @include mq-laptop-l {
      flex-direction: row;
    }

    &__main {
      flex: unset;
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid $gray-light;

      @include mq-laptop-l {
        flex: 1 1 auto;
        padding-bottom: 0;
        margin-right: 40px;
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    &__fio,
    &__position {
      text-align: center;

      @include mq-tablet {
        text-align: left;
      }
    }

    .authInfo {
      display: grid;
      grid-template-rows: min-content min-content;
      grid-gap: 25px;
      width: 100%;

      @include mq-tablet-l {
        min-width: 200px;
        max-width: 250px;
      }
    }
  }
}

.option {
  overflow: hidden;

  &__name {
    color: $gray-darken;
  }

  &__value {
    overflow: hidden;
    color: var(--ws-color-primary);
    text-overflow: ellipsis;
  }
}

.pushedBorder {
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid $gray-light;
}

.label {
  margin-bottom: 7px;
  color: $gray-darken;
}
</style>
