<template>
  <div
    :class="[
      $style.wrapper,
      {
        [$style.wrapper_availableProfile]: this.availableViewProfile,
      },
    ]"
  >
    <router-link
      v-for="listener in listeners"
      v-slot="{ navigate }"
      :key="listener.id"
      :class="$style.listenerCard"
      :to="`listeners/${listener.id}`"
    >
      <user-card :user="listener" @click.native="navigate" />
    </router-link>
  </div>
</template>

<script>
import UserCard from "@/components/common/user/UserCard";

/**
 * Отображение списка участников
 * [Примеры отображения]{@link https://storybook.dev.whenspeak.ru/?path=/docs/listeners-list--without-avatar}
 * @vue-prop {Array} listeners - участники
 * @vue-prop {Boolean} availableViewProfile - доступен ли просмотр профиля участника в комнате
 */
export default {
  name: "RoomListeners",
  components: {
    UserCard,
  },
  props: {
    listeners: {
      type: Array,
      default: () => [],
    },
    availableViewProfile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.listenerCard {
  pointer-events: none;
  cursor: default;
}

.wrapper {
  --column-width: minmax(144px, 1fr);

  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fill, var(--column-width));
  grid-gap: 15px 8px;

  @include breakpoint(475px) {
    --column-width: minmax(200px, 1fr);
  }

  @include mq-tablet-l {
    --column-width: minmax(150px, 1fr);
  }

  &_availableProfile .listenerCard {
    pointer-events: initial;
    cursor: pointer;
  }
}
</style>
