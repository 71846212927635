<template>
  <img
    v-if="src && !error"
    :class="$style.img"
    :src="src"
    :alt="$attrs.alt"
    @error="error = true"
  />
  <div v-else class="flex flex-center" :class="$style.fallback">
    <ws-icon :class="$style.icon" light>user</ws-icon>
    <img src="./transparent.svg" alt="" :class="$style.hidden" />
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
/**
 * Аватар пользователя
 *
 * @vue-prop {string} [src=""] - путь до изображения аватара
 * @vue-data {boolean} error - ошибка загрузки изображения
 */
export default {
  name: "UserAvatar",
  components: {
    WsIcon,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: false,
    };
  },
};
</script>

<style lang="scss" module>
.fallback {
  position: relative;
}

.hidden {
  opacity: 0;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--base-color);
  transform: translate(-50%, -50%);
}

.img,
.hidden {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
