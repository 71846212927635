<template>
  <form :class="$style.form" class="flex flex-column" novalidate enctype="multipart/form-data">
    <loader v-if="inProcess" :animated="false" center />
    <template v-else>
      <label for="upload" :class="$style.label" class="flex flex-center">
        <ws-icon regular color="white" :class="$style.icon">cloud-upload-alt</ws-icon>
        <input
          v-show="false"
          id="upload"
          type="file"
          accept="image/*"
          @change="inputChangeHandler"
        />
      </label>
      <!--      <label-->
      <!--        v-if="$isMobile || $isTablet"-->
      <!--        for="capture"-->
      <!--        :class="$style.label"-->
      <!--        class="flex flex-center"-->
      <!--      >-->
      <!--        <ws-icon regular color="white" :class="$style.icon">camera</ws-icon>-->
      <!--        <input-->
      <!--          v-show="false"-->
      <!--          id="capture"-->
      <!--          type="file"-->
      <!--          accept="image/*;capture=camera"-->
      <!--          @change="inputChangeHandler"-->
      <!--        /> </label-->
      <!--      >-->
    </template>
  </form>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import Loader from "@/components/common/elements/Loader";

/**
 * Компонент для загрузки аватара пользователя
 *
 * Примеры [UserUploadAvatar]{@link http://storybook.dev.whenspeak.ru:6006/?path=/docs/user-uploadavatar--default}
 *
 * @vue-event {Blob} upload - событие на старт загрузки аватара
 * @vue-prop {boolean} inProcess - загрузка в процессе
 */
export default {
  name: "UserUploadAvatar",
  components: {
    WsIcon,
    Loader,
  },
  props: {
    inProcess: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputChangeHandler(event) {
      this.$emit("upload", event.target.files[0]);
    },
  },
};
</script>

<style lang="scss" module>
.form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--base-color);
    opacity: 0.5;
  }
}

.label {
  position: relative;
  z-index: 10;
  flex: 1 0 auto;
  cursor: pointer;

  &:last-child {
    border-top: 1px solid $white;
  }
}

.icon {
  font-size: 30px;

  @include mq-tablet {
    font-size: 44px;
  }
}
</style>
